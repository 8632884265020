/** @jsx jsx */

import { Global, Theme } from "@emotion/react";
import { FC } from "react";
import { Box, jsx, useColorMode } from "theme-ui";

// import Head from 'next/head'
// import Footer from './Footer'

import Helmet from "react-helmet";

import Footer from "@/components/Footer";
import Nav from "@/components/Nav";

const Layout: FC = ({ children }) => {
  const [colorMode, setColorMode] = useColorMode();
  const color = colorMode === `default` ? `default` : `dark`;
  return (
    <Box>
      <Helmet>
        {/* <link href="http://fonts.cdnfonts.com/css/sansumi" rel="stylesheet"/> */}
        {/* <link href="http://fonts.cdnfonts.com/css/avenir-next-lt-pro" rel="stylesheet"/>
         */}
         <link rel="stylesheet" href="https://use.typekit.net/luf1crd.css"/>
      </Helmet>
      <Global
        styles={(theme: Theme) => `
          ::selection {
            background-color: ${theme.colors.selection};
          }
          
          :focus {
            outline: 0px double ${theme.colors.action};
            outline-offset: 0.125em;
          }
          
          body {
            background: ${theme.colors.base};
            margin: 0;
            font: 0.8rem/${theme?.lineHeights?.body} ${theme.fonts.body};
          }
          a {
            text-decoration: none;
            color: ${theme.colors.primary};
          }
      `}
      />

      <Nav fixed />
      <Box as="main">{children}</Box>
      <Footer />
    </Box>
  );
};

export default Layout;
