/** @jsx jsx */

import { FC, useRef, useState, useEffect, createContext } from 'react';
import { Link } from 'gatsby';
import {
  Box,
  Flex,
  Text,
  jsx,
  NavLink,
  Button,
  useColorMode,
  Container,
  MenuButton,
  Image,
  Close,
} from 'theme-ui';

import { motion } from 'framer-motion';
// import { useResponsiveValue, useBreakpointIndex } from '@theme-ui/match-media'
// import { MenuButton, Close } from 'theme-ui';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { NONAME } from 'dns';
import { BrandLogo } from './Icon';


const linksVariants = {
  open: { display: `flex` },
  closed: { display: `block` },
};
const transition = {
  x: { type: `spring`, stiffness: 100 },
  opacity: { ease: `easeIn` },
};

// interface Links {}

interface LinkItem {
  to: string;
  children: any;
  href?: string;
}

const links: LinkItem[] = [
  { to: `/about`, children: `About` },
  // { to: `/testimonials`, children: `Fecility` },
  { to: `/departments`, children: `Departments` },
  { to: `/facility`, children: `Facilities` },
  // { to: `/vision`, children: `Vision` },
  { to: `/contact`, children: `Contact` },
];

export interface NavigationProps {
  alt?: any;
  invert?: boolean;
  fixed?: boolean;
}

const Navigation: FC<NavigationProps> = ({ alt, invert, fixed = true }) => {
  const [colorMode, setColorMode] = useColorMode();
  const [isOpen, setOpen] = useState(false);
  const containerRef = useRef(null);
  // const breakPointIndex = useBreakpointIndex()

  const [hideOnScroll, setHideOnScroll] = useState<boolean>(true);
  const [hideOnScroll2, setHideOnScroll2] = useState<boolean>(false);
  const [prevPosV, setPrevPosV] = useState<any>();
  const [currPosV, setCurrPosV] = useState<any>();

  const [activeVariant, setActiveVariant] = useState<string>(`basic`);
  const [menuState, setMenuState] = useState<string>(`nothing`);

  const [plateColor, setPlateColor] = useState<string>(`primarytext`);

  const bannerHeight = -350;
  const main = `primary`;

  useEffect(() => {
    // console.log('x', prevPosV)
  }, [prevPosV]);

  // useEffect([])

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      setPrevPosV(currPos.y);

      if (currPos.y > bannerHeight) {
        setHideOnScroll2(true);
        // invert && console.log('invertable')
        // !invert && console.log('not invertable')

        // invert && setPlateColor('primarytextinvert');
        // !invert && setPlateColor('primarytext');
      }

      if (currPos.y < bannerHeight) {
        setMenuState(`home`);
        setHideOnScroll2(false);
      }

      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
  );

  return (
    <Box as="header">
      <motion.aside
        ref={containerRef}
        initial
        animate={isOpen ? `open` : `closed`}
        transition={transition}
        sx={{
          opacity: hideOnScroll || hideOnScroll2 ? 1 : 0,
          position: hideOnScroll ? `fixed` : `absolute`,
          flexGrow: 1,
          left: 0,
          right: 0,
          top: 0,
          // bottom: 0,
          bg: 'dim',
          // borderBottom: `solid 1px #ffffff59`,
          zIndex: 1100,
        }}
      >
        <Container>
          <Flex
            as="nav"
            role="navigation"
            sx={{
              position: `relative`,
              // bg: hideOnScroll2 ? `base` : `transparent`,
              
              // minHeight: `7rem`,
              py: 5,
              mb: 0,
              // bg: 'red',
              // px: 5,
            }}
          >
            <Flex variant="navGroup">
              <Link to="/">
                <Box>
                  <Box
                    sx={{
                      color: `red`,
                      svg: {
                        opacity: 1,
                        path: { fill: `white`, '&:hover': { fill: `red.9` } },
                      },
                    }}
                  >
                    {/* <Image src='logo.svg' sx={{ height: `6ch` }}/> */}
                    <BrandLogo
                      sx={{
                        width: '10rem',
                      }}
                    />
                  </Box>
                </Box>
              </Link>
            </Flex>
            <Box as={motion.div} variant="styles.navGroup">
              <Box>
                <Flex
                  as="ul"
                  variant="styles.navList"
                  sx={{
                    m:0,
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    bg: isOpen ? ['primary', 'transparent', 'transparent'] : `transparent`,
                    display: [isOpen ? `flex` : `none`, `flex`, `flex`],
                    flexDirection: ['column', 'row', 'row'],
                    position: [ //
                      isOpen ? `fixed` : `flex`,
                      `relative`,
                      `relative`,
                    ],
                  }}
                >
                  {links.map((_props, index) => (
                    <Box as="li" variant={ isOpen ? `styles.navLink` : `styles.navLink` } key={_props?.to}>
                      {_props?.to && (
                        <NavLink
                          as={Link}
                          variant={ isOpen ? `navList` : `navList` } 
                          // variant=""
                          {...{ to: _props.to }}
                        >
                          {_props.children}
                        </NavLink>
                      )}
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Box>
            <Flex
              as="div"
              sx={{
                bg: `transparent`,
                zIndex: 10000,
                right: 0,
                position: `absolute`,
                mr: 0,
              }}
              key="1"
            >
              <Box
                sx={{
                  color: `primarytextinvert`,
                  px: [0, 3],
                  height: 48,
                  fontSize: 1,
                  display: [`flex`, `flex`, `none`],
                  //   display: [`Home`, `Speaking`].includes(children) ? [`none`, `flex`] : `flex`,
                  alignItems: `center`,
                  fontWeight: `bold`,
                }}
              >
                {!isOpen ? (
                  <MenuButton
                    onClick={() => setOpen(!isOpen)}
                    sx={{
                      float: `right`,
                      color: `white`,
                    }}
                    aria-label="Toggle Menu"
                  />
                ) : (
                  <Close
                    onClick={() => setOpen(!isOpen)}
                    sx={{
                      float: `right`,
                      color: `white`,
                    }}
                    aria-label="Toggle Menu"
                  />
                )}
              </Box>
            </Flex>
          </Flex>
        </Container>
      </motion.aside>
    </Box>
  );
};

export default Navigation;
