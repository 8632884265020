/** @jsx jsx */

import { FC } from 'react';
import {
  Box,
  Flex,
  jsx,
  Text,
  useColorMode,
  Image,
  Link,
  Container,
  NavLink,
} from 'theme-ui';

import { Link as LinkItem } from 'gatsby';
import { BrandLogo } from './Icon';

interface LinkBase {
  to: string;
  children: any;
  href?: string;
}

const linksList: LinkBase[] = [
  { to: `/team`, children: `About` },
  { to: `/testimonials`, children: `Testimonials` },
  { to: `/vision`, children: `Vision` },
  { to: `/contact`, children: `Contact` },
];

const Footer: FC = () => {
  const [colorMode, setColorMode] = useColorMode();

  return (
    <Box
      as="footer"
      variant="styles.plateBase"
      sx={{ borderTop: `solid 1px`,bg: '#fff', borderColor: `grays.2` }}
    >
      <Container>
        <Flex sx={{ flexDirection: [`column`, `column`, `row`] }}>
          <Box
            sx={{
              flexGrow: 1,
              pt: 4,
              //   width: '100%',
              //   bg: 'green',
              textAlign: [`center`, `left`, `left`],
            }}
          >
            <LinkItem sx={{ color: `grays.5` }} to="/">
              <Image src='https://calicutsurgery.com/logo.svg' sx={{ height: `6ch` }}/>
              {/* <BrandLogo /> */}
            </LinkItem>
            <Box sx={{ flexGrow: 1, pb: 3, pt: 3, textAlign: `left` }}>
              <Text
                as="p"
                sx={{
                  textAlign: [`center`, `left`, `left`],
                  fontSize: 0,
                  fontWeight: 200,
                  color: `grays.5`,
                }}
              >
                &copy; 2021 - Dr Ambadi's Calicut Centre For Surgery
              </Text>
            </Box>
          </Box>

          <Flex
            sx={{
              pt: 5,
              flexGrow: 1,
              flexDirection: [`column`, `row`, `row`],
              textAlign: `center`,
            }}
          >
            {linksList.map((ll: LinkBase) => (
              <NavLink as={LinkItem} sx={{ mr: 3, pb: 3, fontSize: 1, fontWeight: 100 }} to={ll.to}>
                {ll.children}
              </NavLink>
            ))}
          </Flex>

          <Box sx={{ pt: 4 }}>
            <Flex
              sx={{
                ml: `auto`,
                pt: 2,
                justifyContent: [`center`, `left`, `left`],
                maxHeight: `48px`,
              }}
            >
               <Link
                href="https://wa.me/+917994400333"
                sx={{ ml: 3 }}
                target="_blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#111" d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
              </Link>


                
              <Link
                href="https://facebook.com/calicutsurgery"
                sx={{ ml: 3 }}
                target="_blank"
              >
                <Image
                  sx={{ width: `32px`, height: `24px` }}
                  src="//s.svgbox.net/social.svg?fill=111&ic=facebook"
                />
              </Link>
              <Link
                href="https://www.instagram.com/calicutsurgery/"
                sx={{ ml: 3 }}
                target="_blank"
              >
                <Image
                  sx={{ width: `32px`, height: `24px` }}
                  src="//s.svgbox.net/social.svg?fill=111&ic=instagram"
                />
              </Link>
              <Box sx={{ pl: 5, textAlign: `right`, display: 'none' }}>
                <Text
                  as="h6"
                  sx={{
                    fontWeight: `body`,
                    textTransform: `uppercase`,
                    color: `grays.5`,
                  }}
                >
                  Theme
                </Text>
                <Text
                  as="h5"
                  onClick={() =>
                    setColorMode(colorMode === `default` ? `dark` : `default`)
                  }
                  sx={{ fontWeight: `heading`, color: `grays.7` }}
                >
                  Dark
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
